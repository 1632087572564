<template>
<div>

  <div class="card-box-full" style="width: 500px">
    <div class="card-box-header">
      Welcome to the Command Center platform
    </div>

    <table width="100%">
      <tr>
        <td width="150" align="center" valign="middle">
          <img src="astronaut.png" width="125" height="125">
        </td>
        <td>

          <CButton @click="openURL('https://legacy-admin.robocalls.ai')" color="warning" style="width: 100%; margin-top: 10px; color: black">
            <CIcon name="cil-warning" style="margin-right: 2px" /> Check out our old admin page
          </CButton>
          <CButton @click="openURL('https://github.com/BSP-Mosaic/teltech-platform.robocalls.ai')" color="info" style="width: 100%; margin-top: 10px">
            <CIcon name="cib-github" style="margin-right: 2px" /> Check us out on GitHub
          </CButton>
          <CButton @click="openURL('https://app.shortcut.com/commandcentr/stories')" color="secondary" style="width: 100%; margin-top: 10px">
            <CIcon name="cil-spreadsheet" style="margin-right: 2px" /> Check us out on Shortcut
          </CButton>

  </td>
</tr>
</table>


  </div>



</div>
</template>

<script>
export default {
  name: 'Dashboard',
  data() {
    return {
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    openURL(url) {
      window.open(url, '_blank');
    }
  },
  mounted() {
  }
}
</script>
